<template>
  <v-input
      :rules="rules"
      :value="inputValue"
  >
    <v-row
        :style="cStyle"
        class="flex-column"
        no-gutters
    >
      <v-col class="flex-grow-0">
        <v-tabs
            v-model="tab"
            fixed-tabs
            height="32"
        >
          <v-tab><i18n path="input.location.position" /></v-tab>
          <v-tab><i18n path="input.location.address" /></v-tab>
        </v-tabs>
      </v-col>
      <v-col style="position: relative;">
        <v-tabs-items
            v-model="tab"
            class="fill-height"
        >
          <v-tab-item class="fill-height">
            <eins-map-detail-position
                :position="cMapCenter"
                :hide-marker="!cHasValidGeolocation"
                :style="{ filter: cHasValidGeolocation ? null : 'blur(5px)' }"
            />
          </v-tab-item>
          <v-tab-item class="fill-height">
            <v-row
                class="fill-height"
                align="center"
                justify="center"
            >
              <v-col class="flex-grow-0">
                <div class="overflow-hidden text-no-wrap pa-1">
                  <template v-if="cAddress">
                    <div class="mb-1">
                      {{ cAddress.street }} {{ cAddress.house_number }}
                    </div>
                    <div class="mb-1">
                      {{ cAddress.postal_code }} {{ cAddress.city }}
                    </div>
                    <div>
                      {{ cAddress.countryName }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="font-italic text-uppercase grey--text text--darken-2">
                      <i18n path="input.location.noAddressSet" />
                    </div>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <v-btn
            color="accent"
            fab
            small
            style="position: absolute; left: 0.5em; bottom: 0.5em; z-index: 400;"
            @click="onClickEdit"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
      <v-col class="flex-grow-0">
        <v-expand-transition>
          <v-alert
              dense
              border="left"
              text
              tile
              type="info"
              class="text-caption mb-0"
          >
            <i18n path="input.location.calculationHint" />
          </v-alert>
        </v-expand-transition>
      </v-col>
      <eins-input-location-position-dialog
          v-model="positionDialog"
          :current-value="cSelectedPosition"
          @submit="onSubmitPosition"
      />
      <eins-input-location-address-dialog
          v-model="addressDialog"
          :current-value="cAddress"
          :dialog-title="dialogTitleAddress"
          :submit-label="submitLabelAddress"
          @submit="onSubmitAddress"
      />
    </v-row>
  </v-input>
</template>

<script>
import { Map } from '@/constants'
import EinsMapDetailPosition from '@/components/map/EinsMapDetailPosition'
import EinsInputLocationPositionDialog from '@/components/input/location/EinsInputLocationPositionDialog'
import EinsInputLocationAddressDialog from '@/components/input/location/EinsInputLocationAddressDialog'
import {
  isValidGeolocation
} from '@/utils/geo'

export default {
  name: 'EinsInputLocation',
  components: {
    EinsInputLocationAddressDialog,
    EinsInputLocationPositionDialog,
    EinsMapDetailPosition
  },
  props: {
    aspectRatio: {
      type: String,
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    },
    dialogTitleAddress: {
      type: String,
      required: true
    },
    submitLabelAddress: {
      type: String,
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tab: null,
    addressDialog: false,
    positionDialog: false,
    internalValue: {
      street: null,
      house_number: null,
      postal_code: null,
      city: null,
      country: null,
      latitude: null,
      longitude: null
    },
    inputValue: null
  }),
  computed: {
    cAddress () {
      if (
        this.internalValue &&
        this.internalValue.street &&
        this.internalValue.house_number &&
        this.internalValue.postal_code &&
        this.internalValue.city &&
        this.internalValue.country
      ) {
        return {
          street: this.internalValue.street,
          house_number: this.internalValue.house_number,
          postal_code: this.internalValue.postal_code,
          city: this.internalValue.city,
          country: this.internalValue.country,
          countryName: this.$t(`countries.names.${this.internalValue.country}`)
        }
      }

      return null
    },
    cSelectedPosition () {
      if (this.cHasValidGeolocation) {
        return {
          latitude: this.internalValue.latitude,
          longitude: this.internalValue.longitude
        }
      }

      return null
    },
    cUserPosition () {
      const result = {
        latitude: this.$store.getters['players/current/latitude'],
        longitude: this.$store.getters['players/current/longitude']
      }

      return isValidGeolocation(result) ? result : null
    },
    cHasValidGeolocation () {
      return isValidGeolocation({
        lat: this.internalValue.latitude,
        lon: this.internalValue.longitude
      })
    },
    cMapCenter () {
      let center
      if (this.cSelectedPosition) {
        center = this.cSelectedPosition
      } else if (this.cUserPosition) {
        center = this.cUserPosition
      } else {
        center = {
          latitude: Map.CENTER_DEFAULT_LAT,
          longitude: Map.CENTER_DEFAULT_LNG
        }
      }

      return {
        lat: center.latitude,
        lon: center.longitude
      }
    },
    cStyle () {
      const result = {}

      if (this.height) {
        result.height = typeof this.height === 'number'
          ? `${this.height}px`
          : this.height
      } else {
        result.aspectRatio = this.aspectRatio
          ? this.aspectRatio
          : 1.25
      }

      return result
    }
  },
  watch: {
    value: {
      handler: 'onChangeValue',
      immediate: true,
      deep: true
    },
    internalValue: {
      handler: 'onChangeInternalValue',
      immediate: true,
      deep: true
    }
  },
  methods: {
    onChangeValue (value) {
      this.applyValue(value)
    },
    onChangeInternalValue (value) {
      this.inputValue = { ...value }
    },
    onSubmitAddress (address) {
      this.setPosition(null)
      this.setAddress(address)
      this.emitInput()
    },
    onSubmitPosition (position) {
      this.setPosition(position)
      this.setAddress(null)
      this.emitInput()
    },
    applyValue (value) {
      if (isValidGeolocation(value)) {
        this.setPosition(value)
      } else {
        this.setAddress(value)
      }
    },
    setPosition (position) {
      this.internalValue.latitude = position?.latitude ?? null
      this.internalValue.longitude = position?.longitude ?? null
    },
    setAddress (address) {
      this.internalValue.street = address?.street ?? null
      this.internalValue.house_number = address?.house_number ?? null
      this.internalValue.postal_code = address?.postal_code ?? null
      this.internalValue.city = address?.city ?? null
      this.internalValue.country = address?.country ?? null
    },
    onClickEdit () {
      if (this.tab === 1) {
        this.showAddressDialog()
      } else {
        this.showPositionDialog()
      }
    },
    showAddressDialog () {
      this.addressDialog = true
    },
    showPositionDialog () {
      this.positionDialog = true
    },
    emitInput () {
      this.$emit('input', { ...this.internalValue })
    }
  }
}
</script>

<style scoped>

</style>
