<template>
  <v-dialog
      :value="internalValue"
      @input="onInputDialog"
  >
    <v-card class="edit-dialog-content">
      <v-card-title class="text-h5">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text
          style="max-height: 55vh"
          class="overflow-y-auto"
      >
        <v-form
            ref="form"
            v-model="form.valid"
        >
          <eins-form-address v-model="form.data" />
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-column">
        <v-btn
            block
            color="primary"
            :disabled="!form.valid"
            @click="onClickSubmit"
        >{{ submitLabel }}</v-btn>
        <v-btn
            block
            class="mx-0 mt-2"
            outlined
            @click="onClickCancel"
        ><i18n path="msc.actions.cancel" /></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsFormAddress from '@/components/form/EinsFormAddress'

export default {
  name: 'EinsInputLocationAddressDialog',
  components: {
    EinsFormAddress
  },
  props: {
    currentValue: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      required: true
    }
  },
  data: () => ({
    internalValue: false,
    form: {
      data: {
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        country: ''
      },
      valid: false
    }
  }),
  computed: {
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onUpdateValue'
    }
  },
  methods: {
    onUpdateValue (value) {
      this.setValue(value)
      this.reset()
    },
    onInputDialog (value) {
      this.setValue(value)
      this.emitInput()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    setValue (value) {
      this.internalValue = value
    },
    emitInput () {
      this.$emit('input', this.internalValue)
    },
    submit () {
      this.$emit('submit', { ...this.form.data })
      this.closeDialog()
    },
    closeDialog () {
      this.internalValue = false
      this.emitInput()
      this.reset()
    },
    reset () {
      this.success = false
      this.form.data = this.currentValue
        ? {
          street: this.currentValue.street,
          house_number: this.currentValue.house_number,
          postal_code: this.currentValue.postal_code,
          city: this.currentValue.city,
          country: this.currentValue.country ?? 'DE'
        }
        : { street: '', house_number: '', postal_code: '', city: '', country: 'DE' }
      this.resetValidation()
    },
    resetValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>

<style scoped>

</style>
